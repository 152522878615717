<template>
  <div id="error404" class="flex-align">
    <a-result status="404" title="404" :sub-title="$t('pages_router_013')">
      <template #extra>
        <a-button type="primary" @click="$router.replace('/home/index')">{{ $t('pages_router_012') }}</a-button>
      </template>
    </a-result>
  </div>
</template>
<style lang="less" scoped>
#error404 {
  justify-content: center;
  height: 100vh;
}
</style>

